<template>
  <!-- 首页 -->
  <div>
    <!-- 导航栏 -->
    <Footer></Footer>
    <div id="content">
      <!-- 轮播图图片 -->
      <Slideshow @contest="toContest" ref="child"></Slideshow>
      <!-- 大赛简介 -->
      <div class="Brief">
        <div class="Briefbox">
          <div class="contbox">
            <div class="title">大赛简介</div>
            <span
              class="cont">  为深入学习贯彻党的二十大精神，落实《中国教育现代化2035》文件精神，深化校企合作，促进产教融合，创新高校人才培养机制，构建信息通信产业人才发展新格局，培养一批具有创新能力和实践能力的技术技能人才，工业和信息化部人才交流中心和中国通信企业协会主办，中信科移动通信技术股份有限公司、大唐移动通信设备有限公司、北京市教委北京高校电子信息类专业群共同承办了“大唐杯”全国大学生新一代信息通信技术大赛，欢迎广大高校师生踊跃参与。</span>
            <p class="seeMore" @click="More">了解更多 >>></p>
          </div>
        </div>
        <div>
          <video id="myVideo" width="100%" height="auto" autoplay="" controls controlsList="nodownload" disablePictureInPicture="true"
            oncontextmenu="return false" poster="./HomePage_img/homeVideoCover.png" ref="myAudio"
            :src="playSrc"></video>
        </div>
        <div id="applicBox"><img src="./HomePage_img/jump.jpg" /></div>
        <div id="leftBtn" @click="jumpTo"></div>
        <div id="rightBtn"  @click="apply"></div>
      </div>
      <!-- 参赛流程 -->
      <div class="Flow">
        <p class="Flow_title">
          “大唐杯”全国大学生新一代信息通信技术大赛 参赛流程指南
        </p>

        <div class="box">
          <div class="box1">
            <img style="position: absolute; height: 2.3rem" src="./HomePage_img/C&1.png" alt="" />
            <!-- 图标 -->
            <!-- <span>
                  <img  class="inco" src="./HomePage_img/icon.png" alt="">
                </span> -->
            <!-- 标题 -->
            <span class="inco2">省赛报名</span>
            <!-- 内容 -->
            <span class="inco3">
              <span>以学校为单位，由教师代表统一报名的模式，学生无法直接进行报名。具体请下载阅读竞赛通知。</span>
            </span>
            <!-- 下载 -->
            <span class="inco4" @click="skip">点击下载竞赛通知</span>
          </div>
          <div class="box1">
            <img style="position: absolute; height: 2.3rem" src="./HomePage_img/C&2.png" alt="" />
            <!-- 图标 -->
            <!-- <span>
                  <img  class="inco" src="./HomePage_img/icon0.png" alt="">
                </span> -->
            <!-- 标题 -->
            <span class="inco2">缴费审核通过</span>
            <!-- 内容 -->
            <span class="inco3">
              <span>教师代表收集并上传报名信息后进行缴费，后续由组委会进行审核。审核通过后会发送邮件通知。</span>
            </span>
            <!-- 下载 -->
            <span class="inco4" @click="skip">点击下载竞赛章程</span>
          </div>
          <div class="box1">
            <img style="position: absolute; height: 2.3rem" src="./HomePage_img/C&3.png" alt="" />
            <!-- 图标 -->
            <!-- <span>
                  <img  class="inco" src="./HomePage_img/icon1.png" alt="">
                </span> -->
            <!-- 标题 -->
            <span class="inco2">大赛培训</span>
            <!-- 内容 -->
            <span class="inco3">
              <span>缴费审核通过后，会为参赛师生开通学唐平台账号，从官网跳转登录后即可进行学习。</span><br />
            </span>
            <!-- 下载 -->
            <span class="inco4" @click="skip">点击下载大纲</span>
          </div>
          <div class="box1">
            <img style="position: absolute; height: 2.3rem" src="./HomePage_img/C&4.png" alt="" />
            <!-- 图标 -->
            <!-- <span>
                  <img  class="inco" src="./HomePage_img/icon2.png" alt="">
                </span> -->
            <!-- 标题 -->
            <span class="inco2">省赛&emsp;&emsp;</span>
            <!-- 内容 -->
            <span class="inco3">
              <span>省赛竞赛时间请参考省赛通知，省赛选拔优胜者进入国赛阶段。</span><br />
            </span>
            <!-- 下载 -->
            <span class="inco4" @click="skip">点击下载省赛通知</span>
          </div>
          <div class="box1">
            <img style="position: absolute; height: 2.3rem" src="./HomePage_img/C&5.png" alt="" />
            <!-- 图标 -->
            <!-- <span>
                  <img  class="inco" src="./HomePage_img/icon3.png" alt="">
                </span> -->
            <!-- 标题 -->
            <span class="inco2">国赛&emsp;&emsp;</span>
            <!-- 内容 -->
            <span class="inco3">
              <span>国赛时间请参考国赛竞赛通知，国赛优胜者获得丰厚奖金与比赛证书。</span>
            </span>
            <!-- 下载 -->
            <span class="inco4" @click="skip"> 点击下载国赛通知</span>
          </div>
          <div class="box1">
            <img style="position: absolute; height: 2.3rem" src="./HomePage_img/C&6.png" alt="" />
            <!-- 图标 -->
            <!-- <span>
                  <img  class="inco" src="./HomePage_img/icon4.png" alt="">
                </span> -->
            <!-- 标题 -->
            <span class="inco2">颁奖典礼</span>
            <!-- 内容 -->
            <span class="inco3">
              <span>大赛闭幕式南北赛区分开举办，优秀选手与教师受邀参加，进行现场颁奖。</span>
            </span>
            <!-- 下载 -->
            <span class="inco4" @click="skip"> 点击下载大赛闭幕式通知</span>
          </div>
        </div>
      </div>
      <!-- 挑战杯 -->
      <!-- <div class="challenge">
        <img @click="challenge" src="./HomePage_img/challenge.jpg" alt="" />
      </div> -->
      <!-- 创新赛 -->
      <div class="innovation">
        <div class="content" @click="innovation">
          <img class="innovationImg" src="./HomePage_img/innovationCopy.jpg" alt="" />
          <span class="innovationText">创新赛入口</span>
        </div>
      </div>
      <!-- 关联赛事 -->
      <div class="Dynamic">
        <div class="Dynamic_cent">
          <span>关联赛事&emsp;“大唐杯”全国双师型教师新一代信息通信技术大赛</span>
        </div>
        <!-- 内容 -->
        <div class="Dynamic_cent_two">
          <div class="Dynamic_cent3">
            <img class="Dynamic_img" src="./HomePage_img/musician-trumpet-metal-snowman-39348.jpg" alt="" />
            <div class="Dynamic_Men">
              <span class="Dynamic_text">“大唐杯”全国双师型教师新一代信息通信技术大赛第十届报名预热中</span>
              <span class="Dynamic_Dow">报名方式点击下方了解更多进入</span>
              <span class="Dynamic_bottm" @click="Liao">了解更多 &emsp;&emsp;——</span>
            </div>
          </div>
          <div class="Dynamic_cent4">
            <img class="Dynamic_img" src="./HomePage_img/pexels-photo-256514.jpg" alt="" />
            <div class="Dynamic_Men">
              <span class="Dynamic_text">第九届“大唐杯”全国双师型教师新一代信息通信技术大赛往届回顾</span>
              <span class="Dynamic_bottm" @click="LiaoTwo">了解更多 &emsp;&emsp;——</span>
            </div>
          </div>
          <div class="Dynamic_cent5">
            <img class="Dynamic_img" src="./HomePage_img/pexels-photo-256516.jpg" alt="" />
            <div class="Dynamic_Men">
              <span class="Dynamic_text">“大唐杯”全国双师型教师新一代信息通信技术大赛官方文件下载</span>
              <span class="Dynamic_bottm" @click="Liao">了解更多 &emsp;&emsp;——</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 大赛介绍 -->
      <div class="Introduce">
        <!-- 领导寄语 -->
        <span class="Introduce_Jy">领导寄语</span>
        <!-- 轮播图 -->
        <div class="Introduce_img">
          <div class="block">
            <el-carousel :interval="5000" arrow="always" height="" style="overflow: hidden">
              <el-carousel-item v-for="item in slideshowData" :key="item">
                <span class="Introduce_text">{{ item.content }}</span>
                <div class="Introduce_box">
                  <span class="portrait">
                    <el-row class="demo-avatar demo-basic">
                      <el-col :span="12">
                        <div class="demo-basic--circle">
                          <div class="block">
                            <el-avatar style="
                                    position: absolute;
                                    width: 0.8rem;
                                    height: 0.8rem;
                                  " :src="circleUrl">
                              <div class="block">
                                <el-avatar :size="100" :src="item.circleUrl"></el-avatar>
                              </div>
                            </el-avatar>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </span>
                  <span class="portrait_T">{{ item.name }}</span><br />
                  <span class="portrait_t">{{ item.position }}</span>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div class="Tail">
        <!-- 常见问题 -->
        <div class="Problem">
          <div class="Problem_box">
            <img style="height: 4.8rem" src="./HomePage_img/Q&A.png" alt="" />
            <span class="Problem_tit"> “大唐杯”参赛常见问题&回答 </span>
            <span class="Problem_tit_two" @click="relation">
              联系我们获取更多帮助
            </span>
          </div>
          <div class="tree">
            <el-tree :data="data" accordion node-key="id" :default-expanded-keys="[1]" :props="defaultProps"
              @node-click="handleNodeClick">
            </el-tree>
          </div>
        </div>
      </div>
      <!-- 咨询 -->

      <div style="
              display: flex;
              justify-content: center;
              background: #f4f6fc;
              height: 7rem;
            ">
        <div class="Consult">
          <div class="Consult_left">
            <img class="Consult_img" src="./HomePage_img/pexels-photo-13743847.png" alt="" />
            <span class="Consult_title">赛事合作与产教融合&赛点申请与基地建设</span>
            <span
              class="Consult_text">“大唐杯”大赛提供开放共赢的合作平台，包括赛区级别的线下赛事承办、赛点建设与合作、竞赛平台建设、虚拟仿真实验室共建、校企融合产业基地共建等等，亟待您与我们的合作。</span>
          </div>
          <div class="Consult_right">
            <span class="Consult_con">向我们咨询</span>
            <span class="Consult_content">填写您的信息后点击咨询我们，由竞赛区域主管为您提供定制化的解决方案与合作模式。</span>
            <input type="text" class="Consult_input" placeholder="您的姓名" onfocus="this.placeholder=''"
              onblur="this.placeholder='您的姓名'" />
            <input type="text" class="Consult_input2" placeholder="您的联系方式" onfocus="this.placeholder=''"
              onblur="this.placeholder='您的联系方式'" />
            <input type="text" class="Consult_input3" placeholder="您所在的学校" onfocus="this.placeholder=''"
              onblur="this.placeholder='您所在的学校'" />
            <input type="text" class="Consult_input4" placeholder="您所在的省份" onfocus="this.placeholder=''"
              onblur="this.placeholder='您所在的省份'" />

            <button class="Consult_button">咨询我们</button>
            <span class="Consult_bottom" @click="relation">区域划分与合作详情</span>
          </div>
        </div>
      </div>
      <!-- 大赛回顾 -->

      <div class="retrospect_box">
        <span class="Retrospect_tite">大赛回顾</span>

        <div class="Retrospect">
         <div class="Retrospect_cont">
            <img class="Retrospect_img" src="@/assets/images/yuwan/eleOne.png" alt="" />
            <span class="Retrospect_jan">2024-07-24</span>
            <div class="Retrospect_title">
              <span>第十一届“大唐杯”比赛精彩回顾闭幕式暨颁奖典礼</span>
            </div>
            <span class="Retrospect_content">2024年7月10日至24日，第十一届“大唐杯”全国大学生新一代信息通信技术大赛暨全国双师型教师新一代信息通信技术大赛（以下简称：大赛或大唐杯）全国总决赛武汉站、北京站分别在武汉职业技术学院、武汉理工大学</span>
            <span class="Retrospect_foot" @click="viewDetail('elevenB')">
              阅读详情>>>
            </span>
          </div>

          <div class="Retrospect_cont">
            <img class="Retrospect_img" src="./HomePage_img/pexels-photo-1752806.png" alt="" />
            <span class="Retrospect_jan">2023-09-01</span>
            <div class="Retrospect_title">
              <span>第十届“大唐杯”比赛精彩回顾闭幕式暨颁奖典礼</span>
            </div>
            <span class="Retrospect_content">第十届“大唐杯”全国大学生新一代信息通信技术大赛暨全国双师型教师新一代信息通信技术大赛全国总决赛北京站、武汉站分别在北方工业大学、武汉职业技术学院落下帷幕。</span>
            <span class="Retrospect_foot" @click="viewDetail('seven')">
              阅读详情>>>
            </span>
          </div>
          <div class="Retrospect_cont">
            <img class="Retrospect_img" src="./HomePage_img/pexels-photo.png" alt="" />
            <span class="Retrospect_jan"> 2022-07-20</span>
            <div class="Retrospect_title">
              <span>第九届“大唐杯”比赛精彩回顾省赛&国赛&全国双师型教师赛</span>
            </div>
            <span
              class="Retrospect_content">大赛以“加速5G建设，服务5G新场景，赋能5G新应用”为己任，不断深化同高校在5G实训平台、双师培养、课程体系建设、5G+垂直产业应用等方面融合，助力高校实现人才培养和教学质量双提升。</span>
            <span class="Retrospect_foot" @click="viewDetail('nineN')">
              阅读详情>>>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 二维码固定 -->
    <div data-v-73a23e1a="" data-v-3ec6243f="" class="com-contact-wrapper anmiated bounceIn">
      <div data-v-73a23e1a="" class="com-contact-btn com-contact-btn-pc">
        <img src="./HomePage_img/code.jpg" alt="" /><br>
        <span>微信扫码关注我们</span><br>
        <span>获取更多赛事信息</span>
      </div>
    </div>

    <!-- 创新赛固定 -->
    <div class="chuang_box">
      <div data-v-73a23e1a="" class="chuang_box2" @click="innovation">
        <img src="./HomePage_img/competition.jpg" alt="" /><br>
      </div>
    </div>
    <!-- 底部 -->
    <Bottom></Bottom>
  </div>
</template>

<script>
import Footer from "@/components/Footer/Footer.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import Slideshow from "@/components/slideshow/slideshow.vue";

import $ from "jquery";

export default {
  name: "Home",
  components: {
    Footer,
    Bottom,
    Slideshow,
  },
  data() {
    return {
      data: [
        {
          id: 1,
          label: "我该如何报名参赛？",
          children: [
            {
              id: 1,
              label:
                "大唐杯采用院校教师代表集中报名的形式，先由专业教师注册报名账号，登录后进入报名界面，按照报名模板填写所有参赛选手信息，缴纳报名费后完成报名。组委会为报名成功的选手开通学唐平台账号进行学习。不提供学生独自报名方式。",
            },
          ],
        },
        {
          id: 2,
          label: "我该如何进行备考与学习",
          children: [
            {
              label:
                "大赛拥有学唐Online平台与虚拟仿真平台两个培训平台，分别对应培训理论赛与仿真实践赛，参赛选手可以点击官网右上角的大赛培训进入学习平台。",
            },
          ],
        },
        {
          id: 3,
          label: "在哪里可以下载官方文件？",
          children: [
            {
              label:
                "点击官网顶部的官方文件，即可进入官方文件发布页面，下载所需要的官方文件。",
            },
          ],
        },
        {
          id: 4,
          label: "如何查询自己的获奖证书？",
          children: [
            {
              label:
                "可以登录工信部人才进行证书查询,查询地址:https://www.ncie.org.cn/Category_1397/Index.aspx",
            },
          ],
        },
        {
          id: 5,
          label: "其他问题咨询 ",
          children: [
            {
              label:
                "点击左侧的联系我们获取更多帮助，找到对应区域的负责老师即可进行咨询。",
            },
          ],
        },
      ],
      state:false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      slideshowData: [
        {
          content:
            "大赛积极面向工业数字化转型的挑战，倡导“绿水青山就是金山银山”理念，践行双碳发展要求，以经济决策分析推动新工科人才培养方式的创新，顺应产业和行业发展要求不断创新大赛的内容、深度和广度。",
          position: "致辞嘉宾",
          name: "栗苹",
          circleUrl: require("./HomePage_img/Head4.png"),
        },
        {
          content:
            "大赛以真设备、真场景、真问题作为竞赛核心，选手在5G技术原理、5G工程实践及5G+创新应用三方面进行了真设备实践、真场景应用、真问题解决等工程能力比拼。",
          position: "致辞嘉宾",
          name: "曾卫明",
          circleUrl: require("./HomePage_img/Head3.png"),
        },
        {
          content:
            "以“加速5G建设，服务5G新场景，赋能5G新应用”为目标，不断深化同全国高校在5G通信技术实训平台、双师培养、高质量课程体系建设。",
          position: "致辞嘉宾",
          name: "卞佳丽",
          circleUrl: require("./HomePage_img/Head2.png"),
        },
        {
          content:
            "不断深化同校企在5G通信技术实训平台、高质量课程体系建设、5G+垂直产业创新应用等方面的深度融合，持续推动高校实现人才培养和教学质量双提升，实现“企业用人”与“高校育人”双循环。",
          position: "致辞嘉宾",
          name: "蔡鑫",
          circleUrl: require("./HomePage_img/Head.png"),
        },
      ],
    };
  },
  created(){
    this.getVideoUrl()
  },
  mounted() {
    this.$refs.child.sing();
  },
  methods: {
    getVideoUrl(){
      // this.playSrc = 'https://picture-file-public-read-dt.oss-cn-hangzhou.aliyuncs.com/dt_cup/video/index/%E9%A6%96%E9%A1%B5%E7%AE%80%E4%BB%8B.mp4'
      // this.$nextTick(res => {
      //   this.$refs.myAudio.load()
      //   this.$refs.myAudio.play()
      // })
    },
    //去报名
    toContest(val) {
      console.log(val)
      if (!val) return;
      this.$router.push("/Register");
    },
    // 创新赛
    innovation() {
      this.$router.push("/innovate");
    },
    handleNodeClick(data) {
      console.log(data);
    },
    relation() {
      this.$router.push("/Contact");
    },
    skip() {
      this.$router.push("/File");
    },
    // 了解更多
    More() {
      this.$router.push("/Brief");
    },
    // 了解更多2
    Liao() {
      this.$router.push("/TeachersGet");
    },
    LiaoTwo() {
      this.$router.push("/Journalism");
    },
    // 阅读详情
    viewDetail(type) {
      this.$router.push({
        path: "/ViewDetail",
        query: {
          vType: type,
        },
      });
    },

    jumpTo() {
      this.$router.push("/Register");
      },
      apply(){
        this.$router.push('/Train');
      },
  },
};
</script>

<style scoped lang="scss">
.el-carousel__item h3 {
  color: #475669;
  font-size: 0.14rem;
  opacity: 0.9;
  line-height: 200px;
  margin: 0;
}

/deep/.el-carousel__indicators--horizontal {
  display: none !important;
}

.Introduce_img /deep/.el-carousel__arrow--left {
  left: 80%;
  top: 1.4rem;
}

.Introduce_img /deep/ .el-carousel__arrow--right {
  left: 92%;
  top: 1.4rem;
}

/deep/ .el-row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: absolute;
  top: 10%;
  left: 6%;
}

.tree {
  ::v-deep .el-tree-node {
    white-space: normal; //关键代码!!!!!!!!!

    .el-tree-node__content {
      height: 100%;
      align-items: start;
    }
  }
}

/deep/ .el-tree {
  position: absolute;
  left: 2.8rem;
  width: 7.6rem;
  opacity: 1;
  background: #e1ebf2;
  --el-tree-node-hover-bg-color: #e1ebf2;
}

//一级节点选择器
// /deep/ .el-tree>.el-tree-node> .el-tree-node__content {
//   font-weight: 900;
//   color: #333333;
// }
//二级节点选择器
/deep/ .el-tree>.el-tree-node>.el-tree-node__children>.el-tree-node>.el-tree-node__content>.el-tree-node__label {
  color: #000;
  font-size: 0.15rem;
  margin: auto 0;
  text-indent: 2em;
}

/deep/.el-tree-node__expand-icon {
  font-size: 0.26rem;
  margin: auto 0;
}

/deep/.el-tree-node__content {
  height: 0.8rem !important;
  border-bottom: 1px solid#fff;
  opacity: 0.9;
}

/deep/.el-tree-node__label {
  opacity: 1;
  font-family: Poppins-Medium;
  font-size: 0.24rem;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000;
  margin: auto 0;

  // /*第一步： 溢出隐藏 */
  // overflow: hidden;
  // /* 第二步：让文本不会换行， 在同一行继续 */
  // white-space: nowrap;
  // /* 第三步：用省略号来代表未显示完的文本 */
  // text-overflow: ellipsis;
}

/deep/.el-tree-node__children {
  opacity: 0.9;
  font-size: 0.16rem;
  letter-spacing: 0px;
  color: #000;
}

.Introduce_img /deep/.el-carousel__item:nth-child(2n) {
  // background-color: #CFDAE6;
}

.Introduce_img /deep/.el-carousel__item:nth-child(2n + 1) {
  // background-color: #CFDAE6;
}

.com-contact-wrapper[data-v-73a23e1a] {
  position: fixed;
  bottom: 15%;
  right: .2rem;
  z-index: 2000;
}

.com-contact-wrapper .com-contact-btn-pc[data-v-73a23e1a] {
  padding: .16rem .2rem .1rem;
  background: #334f79;
  color: #fff;
  font-size: .12rem;
  text-align: center;
  cursor: pointer;

  img {
    width: 1.6rem;
    height: 1.6rem;
  }

}
.chuang_box{
  position: fixed;
  bottom: 40%;
  right: .2rem;
  z-index: 2000;
  display: none;
}
.chuang_box2 {
  cursor: pointer;
  img {
    width: 2rem;
    height: 2.1rem;
  }

}

@import "./HomePage.scss";
</style>